import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {UntypedFormControl, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ButtonActionTypes, ZenDialogActionButton, ZenDialogComponent} from '../zen-dialog/zen-dialog.component';
import {ZenBaseWithTranslateComponent} from '../../_components/zen-base-with-translate/zen-base-with-translate.component';
import {UnprocurableMeterInfo, UnprocurableMeterSummary} from '../../../_modules/portfolio/_model/portfolio-meters.model';
import {MetersV4Service} from '../../_services/v4/meters-v4.service';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {PortfolioHelperService} from '../../../_modules/portfolio/_services/_helpers/portfolio-helper.service';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import moment from 'moment';

export interface ZenProcurementStatusDialogModel {
  customerId: number;
  states: string[];
  commodityTypes: CommodityType[];
  lenIds?: string[];
  meterIds?: number[];
  serviceAddressIds?: number[];
  meterLevelInfo?: UnprocurableMeterInfo[];  // Gathered from the meters and passed along
  onClose?: () => void;
  onComplete?: () => void;
}

export enum SelectionLevel {
  meter = 'meter',
  serviceAddress = 'serviceAddress',
  len = 'len'
}

@Component({
  selector: 'app-zen-procurable-status-dialog',
  templateUrl: './zen-procurable-status-dialog.component.html',
  styleUrl: './zen-procurable-status-dialog.component.scss'
})
export class ZenProcurableStatusDialogComponent extends ZenBaseWithTranslateComponent implements OnInit {
  unprocurableStatusData = [
    { id: 1, name: 'Low Usage', checked: true },
    { id: 2, name: 'Load Factor', checked: true  },
    { id: 3, name: 'Rate Class', checked: true  },
    { id: 4, name: 'Back Up Generation', checked: true  },
    { id: 5, name: 'Telemetry', checked: true  },
    { id: 6, name: 'Customer Directed', checked: true  },
    { id: 7, name: 'Utility Strategic', checked: true  },
    { id: null, name: 'Procurable', checked: true  }
  ];
  ZenIcons = ZenIconsEnum;
  Commodity = CommodityType;
  selectionLevel: SelectionLevel;
  meterCountMap: { [key: number]: number } = {};
  showLoadingSpinner = false; // used to control button action loader
  clickedButtonLabel: string; // used to detect button which button was clicked if there are several buttons
  appearance: MatFormFieldAppearance = 'outline';
  tomorrow: Date = moment().add(1, 'days').toDate();
  procurableChecked = true;
  unprocurableChecked = true;
  showUnprocurableReasons = false;
  unprocurableIndeterminate = false;
  isSingleCheckboxScenario = false;
  // Variables to track counts of meters
  unprocurableMeterCount: number;
  procurableMeterCount: number;
  electricMeterTotalCount: number;
  gasMeterTotalCount: number;

  ButtonActionTypes =  ButtonActionTypes;
  bodyHtml: string;
  slider = {
    label: 'Procurable',
    formCtrl: new UntypedFormControl(false) // Initialize as unchecked (false)
  };
  unprocurableDropdown = {
    label: 'Reason',
    formCtrl: new UntypedFormControl('', Validators.required),
    options: [] // Pass transformed options here
  };
  unprocurableSetUntil = new UntypedFormControl(null, []);
  unprocurableNotes = new UntypedFormControl(null, []);
  selectedCommodity = new UntypedFormControl(); // CommodityType

  unprocurableStatusBodyText = null;
  commoditySelectionBodyText = 'Your selection contains meters with multiple commodity types. Select commodity you would like to change?';


  meterInfo: UnprocurableMeterInfo[];
  unprocurableMeterSummary: UnprocurableMeterSummary;

  existingNotesHtml: string;

  loading: boolean;


  constructor(
    public dialogRef: MatDialogRef<ZenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ZenProcurementStatusDialogModel,
    public translateSvc: TranslateService,
    private meterV4Svc: MetersV4Service,
    private zenDialogSvc: ZenDialogMsgService,
    private pfHelpSvc: PortfolioHelperService
  ) {
    super(translateSvc);

    // Initialize dropdown form control if not already provided
    if (this.unprocurableDropdown && !this.unprocurableDropdown.formCtrl) {
      this.unprocurableDropdown.formCtrl = new UntypedFormControl('', Validators.required);
    }

    this.assignSelectionLevel();
  }

  ngOnInit(): void {
    this.loading = true;
    this.slider?.formCtrl.valueChanges.subscribe((value: boolean) => {
      this.handleSliderValueChange(value);
    })
    this.handleSliderValueChange(this.slider?.formCtrl.value);
    this.getAndProcessUnprocurableMeterInfo();
  }

  // Check the data and let us know if this is a meter/len/serviceAddress level change,  used to determine which APIs to call
  assignSelectionLevel() {
    if (this.data.lenIds != null && this.data.lenIds.length > 0) {
      this.selectionLevel = SelectionLevel.len;
    }
    if (this.data.serviceAddressIds != null && this.data.serviceAddressIds.length > 0) {
      this.selectionLevel = SelectionLevel.serviceAddress;
    }
    if (this.data.meterIds != null && this.data.meterIds.length > 0) {
      this.selectionLevel = SelectionLevel.meter;
    }
  }

  updateUnprocurableMeterCount() {
    this.unprocurableMeterCount = this.unprocurableStatusData
      .filter(status => status.id !== null)
      .reduce((acc, status) => acc + (this.meterCountMap[status.id] || 0), 0);

    this.procurableMeterCount = this.unprocurableStatusData
      .filter(status => status.id == null)
      .reduce((acc, status) => acc + (this.meterCountMap[status.id] || 0), 0);

    this.showUnprocurableReasons = true;  // Always show by default

    // Set the isSingleCheckboxScenario variable, including null
    const uniqueIds = new Set(Object.keys(this.meterCountMap).map(id => id === 'null' ? null : Number(id)));

    // If there's only one unique ID, set it to true
    this.isSingleCheckboxScenario = uniqueIds.size === 1;
  }
  /**
   * Uses the meter Info to create a map, so we can tell how many meters are under each status
   */
  updateMeterCountMap() {
    // Initialize totals for each commodity type
    let electricityTotal = 0;
    let gasTotal = 0;

    // If we have a commodity type, we will filter out results to match that commodity type
    // Otherwise we'll show both counts so the users can select a commodity type
    const commodityType = this.selectedCommodity.value;

    this.meterCountMap = this.meterInfo.filter(m => commodityType == null || m.commodityType == commodityType)
      .reduce((acc, info) => {
      // Sum total counts for each commodity type
      if (info.commodityType === CommodityType.Electricity) {
        electricityTotal += info.meterCount;
      } else if (info.commodityType === CommodityType.Gas) {
        gasTotal += info.meterCount;
      }

      acc[info.unprocurableStatusId] = info.meterCount;
      return acc;
    }, {});

    // Update totals
    this.electricMeterTotalCount = electricityTotal;
    this.gasMeterTotalCount = gasTotal

    // If there is a commodity type, generate the breakdown by reason for meters
    if (commodityType != null) {
      this.updateUnprocurableMeterCount();
      this.updateUnprocurableStatusData();
    } else {
      this.loading = false;
    }
  }

  // Generate the breakdown of how many meters exist for each reason, for a particular commodity type
  updateUnprocurableStatusData() {
    this.meterV4Svc.getUnprocurableStatus(this.data.customerId, this.data.states, this.data.commodityTypes).subscribe((statuses) => {

      const meterCount = this.selectedCommodity.value == CommodityType.Electricity ? this.electricMeterTotalCount : this.gasMeterTotalCount;
      this.unprocurableStatusBodyText =   this.getProcurementStatusBodyText(meterCount, this.selectedCommodity.value);
      // Generate unprocurableStatusData from the service response

      this.unprocurableStatusData = [ {id: null, name: 'Procurable'}, ...statuses].map(status => ({
        id: status.id,
        name: status.name,
        checked: true
      })).filter(status => this.meterCountMap[status.id] > 0); // Filter out entries with 0 count;

      const dropdownOptions = statuses
        .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name (label)
        .map(status => ({
        label: `${status.name}`,
        value: status.id
      }));
      this.unprocurableDropdown.options = dropdownOptions;

      if (this.procurableMeterCount > 0) {
        this.slider.formCtrl.setValue(true);
      }

      const commodityData = this.selectedCommodity.value === CommodityType.Electricity ?
          this.unprocurableMeterSummary.currentElectricData : this.unprocurableMeterSummary.currentGasData;


      // Hide utility option if not supported
      if (!commodityData.allMetersOnUtility) {
        this.unprocurableDropdown.options = this.unprocurableDropdown.options.filter(x => x.value !== 7);
      }

      this.unprocurableNotes.setValue(commodityData.defaultNote);
      this.unprocurableSetUntil.setValue(commodityData.defaultSetUntil);
      if (commodityData.defaultUnprocurableStatusId != null) {
        const selection = this.unprocurableDropdown.options.find(x => x.value === commodityData.defaultUnprocurableStatusId);
        this.unprocurableDropdown.formCtrl.setValue(selection?.value);
      }
      this.updateExistingNotes();
      this.loading = false;
    })
  }

  /**
   * Call the appropriate API to load data, for meters this is passed in.
   */
  getAndProcessUnprocurableMeterInfo() {
        this.meterV4Svc.getUnprocurableStatusInfo(this.data.customerId,
            this.getIdsBasedOnSelectionLevel(),
            this.selectionLevel).subscribe(unprocurableMeterSummary => {
          this.meterInfo = unprocurableMeterSummary.unprocurableMeterInfoList;
          this.unprocurableMeterSummary = unprocurableMeterSummary;
          this.processMeterInfo();
        });
  }

  getIdsBasedOnSelectionLevel() {
    return this.selectionLevel == SelectionLevel.meter ? this.data.meterIds : this.selectionLevel == SelectionLevel.len ? this.data.lenIds : this.data.serviceAddressIds;
  }

  /**
   * Figure out the commodity types from the data, and auto set commodity type if there is only 1
   */
  processMeterInfo() {
    const distinctCommodityTypes = Array.from(new Set(this.meterInfo.map(m => m.commodityType)));
    this.data.commodityTypes = distinctCommodityTypes;
    // If we have a single commodity type, use that, otherwise capture from user
    if (distinctCommodityTypes.length === 1) {
      this.selectedCommodity.setValue(distinctCommodityTypes[0]);
    }
    this.updateMeterCountMap();
  }

  handleSliderValueChange(value: boolean): void {
      if (!value) {
        this.unprocurableDropdown?.formCtrl.setValidators([Validators.required]);
      } else {
        // Clear the dropdown selection and remove required validation
        this.unprocurableDropdown?.formCtrl.clearValidators();
      }
      this.unprocurableDropdown?.formCtrl.updateValueAndValidity();
  }

  isFormValid(): boolean {
    let isValid = true;
    if (this.slider?.formCtrl && this.slider?.formCtrl.value == false && this.unprocurableDropdown?.formCtrl && !this.unprocurableDropdown.formCtrl.valid) {
      isValid = false;
    }
    return isValid;
  }


  submit() {
    let isValid = this.isFormValid();

    this.unprocurableDropdown?.formCtrl.markAsTouched();
    this.unprocurableDropdown.formCtrl.updateValueAndValidity();
    if (isValid) {
      this.handleBulkUpdate()
    }
  }



  getCheckedMeterValues(): (number)[] {
    // Create a Set to ensure distinct meter count values
    const meterValues = Array.from(new Set(
        this.unprocurableStatusData
            .filter(status => status.checked && status.id != null)
            .map(status => status.id)
    ));

    return meterValues;
  }
  /**
   * Handles submission to backend, will update for meters/lens/serviceAddresses
   */
  handleBulkUpdate() {
    // Get the selected value from the dropdown
    const procurable = this.slider.formCtrl.value;
    let selectedUnprocurableStatus = this.unprocurableDropdown.formCtrl.value === "" ? null : this.unprocurableDropdown.formCtrl.value;
    let unprocurableNotes = this.unprocurableNotes.value == "" ? null : this.unprocurableNotes.value;
    let unprocurableSetUntil = this.unprocurableSetUntil.value;

    // If 'procurable' is true, set the other variables to null
    if (procurable) {
      selectedUnprocurableStatus = null;
      unprocurableNotes = null;
      unprocurableSetUntil = null;
    }

    const targetUnprocurableStatusIds = this.getCheckedMeterValues();

    const targetProcurable = this.procurableChecked && this.procurableMeterCount > 0;


    this.meterV4Svc.bulkChangeUnprocurableStatus(this.data.customerId, this.getIdsBasedOnSelectionLevel(),
        selectedUnprocurableStatus, unprocurableNotes, unprocurableSetUntil, this.selectionLevel, targetUnprocurableStatusIds, targetProcurable, this.selectedCommodity.value)
        .subscribe(res => {
        //  this.pfHelpSvc.openSuccessGuidance('Success');
          this.data.onClose();
          if (this.data.onComplete) {
            this.data.onComplete();
          }
          this.zenDialogSvc.openToast(true, 'Meter Statuses Updated');
        }, e => {
          console.log('ERR: bulk change status Meters ', e);
          this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        });
  }

  /**
   * Generate body text that appears for procurement status modal
   * @param rowCount
   * @param commodityType
   */
  getProcurementStatusBodyText(rowCount: number, commodityType: CommodityType) {
    let bodyHtml;
    const commodityTypeText = commodityType == CommodityType.Electricity ? 'electricity' : 'natural gas';
    const entityText = rowCount === 1 ? 'meter' : 'meters';

    if (this.isSingleCheckboxScenario) {
      bodyHtml = `Your selection contains ${rowCount} ${commodityTypeText} ${entityText}. Use the toggle if you would like to change the procurement status.`;
    } else {
      bodyHtml = `Your selection contains ${rowCount} ${commodityTypeText} ${entityText} with different procurement statuses. Select the ones you would like to change.`;
    }
    return bodyHtml;
  }
  updateCommodity(c: CommodityType) {
      this.data.commodityTypes = [c];
      this.selectedCommodity.setValue(c);
      this.loading = true;
      this.updateMeterCountMap();
  }

  toggleProcurable(event: any) {
    this.procurableChecked = event.checked;
    this.unprocurableStatusData[null as any].checked = this.procurableChecked;
  }

  toggleUnprocurable(event: any) {
    this.unprocurableChecked = event.checked;
    this.unprocurableIndeterminate = false;
    this.unprocurableStatusData.forEach(status => status.checked = this.unprocurableChecked);
  }

  toggleReason(event: any, index: number) {
    this.unprocurableStatusData[index].checked = event.checked;
    this.updateParentCheckboxState();
  }

  updateParentCheckboxState(): void {
    const allChecked = this.unprocurableStatusData.every(status => status.checked);
    const noneChecked = this.unprocurableStatusData.every(status => !status.checked);
    this.unprocurableChecked = allChecked;
    this.unprocurableIndeterminate = !allChecked && !noneChecked;
  }

  toggleShowUnprocurableReasons() {
    this.showUnprocurableReasons = !this.showUnprocurableReasons;
  }

  updateExistingNotes() {
    const commodityData = this.selectedCommodity.value === CommodityType.Electricity
        ? this.unprocurableMeterSummary.currentElectricData
        : this.unprocurableMeterSummary.currentGasData;

    if (commodityData.existingNotes == null || commodityData.existingNotes.length == 0) {
      this.existingNotesHtml = null;
    } else {
      // Assuming commodityData.existingNotes is an array of ExistingNote objects
      this.existingNotesHtml = commodityData.existingNotes.map(note => {
        const formattedDate = new Date(note.createdAt).toLocaleDateString('en-US');
        const fullName = `${note.firstName} ${note.lastName}`;

        return `
      <p class="note-entry">
        <strong>On ${formattedDate} by ${fullName}:</strong> ${note.note}
      </p>
    `;
      }).join('');
    }
  }


}
